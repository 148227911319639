import * as cx from "classnames/bind";
import { ButtonLink, Icon, NavLink } from "components";
import { useDebounce, useQuery, useSession } from "hooks";
import Image from "next/image";
import Link from "next/link";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import React, { useEffect, useState, useRef } from "react";
import {
  useHits,
  useSearchBox,
  Configure,
  InstantSearch,
  Index,
} from "react-instantsearch";
import OutsideClickHandler from "react-outside-click-handler";
import s from "styles/HomeMenu.module.scss";
import { imageUrl, uniqObjArray, translate } from "utils";
import algoliasearch from "algoliasearch";
import Cookies from "js-cookie";

import { useTranslation } from "next-i18next";

import { useHover } from "@uidotdev/usehooks";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
);

let timerId = undefined;
const queryHook = (query, search) => {
  if (timerId) {
    clearTimeout(timerId);
  }
  timerId = setTimeout(() => search(query), 400);
};

const SearchBox = ({
  cocktailsSearchRef,
  searchString,
  setSearchString,
  debouncedSearchString,
  ...props
}) => {
  const { query, refine } = useSearchBox({
    queryHook,
  });
  const { t } = useTranslation();
  const ref = useRef(null);

  const emptyString = () => {
    refine("");
    setSearchString("");
    ref.current.focus();
  };

  return (
    <div>
      <form action={`/cocktails`} method="get">
        <input
          type="text"
          name="s"
          placeholder={t("Search cocktails...")}
          onKeyUp={(e) => {
            refine(e.target.value);
            setSearchString(e.target.value);
          }}
          autoComplete="off"
          ref={ref}
          onFocus={() => {
            if (cocktailsSearchRef && cocktailsSearchRef.current) {
              //cocktailsSearchRef.current.focus();
            } else {
              props.setClosedSearch(false);
            }
          }}
        />
        <div onClick={emptyString} className={cx(s.clearBtn)}>
          <Icon size="far" svg="times" />
        </div>
        <button type="submit">
          <Icon size="far" svg="search" />
        </button>
      </form>
    </div>
  );
};

const ResultsList = ({ index, setTotal, title, setSearchString, ...props }) => {
  const {
    hits,
    results: { nbHits, query },
  } = useHits(props);
  const total = nbHits;
  const results = hits;
  const { t } = useTranslation();

  useEffect(() => {
    setTotal(total);
  }, [total]);

  return (
    <div
      className={cx(
        "searchResults",
        s[`searchResults_${index}`],
        s.searchResults,
        results.length > 0 && !props.closed ? s.open : "",
      )}
    >
      <div className={cx(s.searchResultsTitle)}>
        <b>{title}</b>
        {results.length == 0 && <p>No results found</p>}
      </div>
      <div>
        {results.length > 0 && (
          <div>
            <p className="Bold">
              <small>
                {results.length > 0 && (
                  <>
                    {total} {t("results found.")}&nbsp;
                  </>
                )}
                <Link href={""}>{t("View all")}</Link>
              </small>
            </p>
          </div>
        )}
        <ul>
          {results.map((result) => {
            return (
              <li
                key={result.image_id}
                onClick={() => {
                  props.setClosedSearch(true);
                  setSearchString("");
                }}
              >
                <Link href={`/${index}/${result.slug}`}>
                  <div className={cx(s.searchResultsImage)}>
                    <Image
                      alt={result.name}
                      src={imageUrl(result.image_id)}
                      width={70}
                      height={82}
                    />
                  </div>
                  <div>
                    <div className="Heading__small">{result.name}</div>
                    <p>
                      {(result.spirits || [])
                        .map((spirit, index) => {
                          return `${spirit.name}`;
                        })
                        .join(", ")}
                    </p>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
        {results.length > 0 && (
          <ButtonLink
            onClick={() => {
              props.setClosedSearch(true);
            }}
            href={`/${index}/?s=${query}`}
            className="Primary Full"
          >
            {t("See all results")}&nbsp;({total})
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

const SearchResults = ({ index, filters = "", ...props }) => {
  return (
    <Index indexName={`cocktailclub-${index}-en`}>
      <Configure length="7" offset="0" filters={filters} />
      <ResultsList index={index} {...props} />
    </Index>
  );
};

const SearchHolder = (props) => {
  const { t } = useTranslation();
  const [cocktailsTotal, setCocktailsTotal] = useState(0);
  const [productsTotal, setProductsTotal] = useState(0);

  const [searchString, setSearchString] = useState("");

  const noResults = cocktailsTotal + productsTotal < 1 && searchString != "";

  const country = Cookies.get("__country");
  let filters = "hidden:false AND pro:false AND organization_id:false";
  filters = `${filters} AND ${
    country
      ? `NOT excluded_countries:${country} AND (exclusive_countries:${country} OR is_exclusive:false)`
      : "is_exclusive:false"
  }`;

  const [activeIndex, setActiveIndex] = useState("cocktails");
  const [isMobile, setIsMobile] = useState(false);

  // Function to handle clicks on the search result selector
  const handleSelectorClick = (index) => {
    setActiveIndex(index);
  };

  // Effect hook to check screen width and update isMobile state
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // Example breakpoint for mobile
    };

    // Check on mount and on window resize
    checkMobile();
    window.addEventListener("resize", checkMobile);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <>
      <InstantSearch searchClient={searchClient}>
        <div className={cx("searchHolder", s.searchHolder)}>
          <SearchBox
            {...props}
            searchString={searchString}
            setSearchString={setSearchString}
          />
          <div
            className={cx(
              s.searchResultsHolder,
              searchString != "" && !props.closed ? s.open : "",
            )}
          >
            <div
              className={cx(s.searchResultsSelector, {
                [s.hideOnDesktop]: !isMobile,
              })}
            >
              <span
                className={cx({ [s.active]: activeIndex === "cocktails" })}
                onClick={() => handleSelectorClick("cocktails")}
              >
                Cocktails
              </span>
              <span
                className={cx({ [s.active]: activeIndex === "products" })}
                onClick={() => handleSelectorClick("products")}
              >
                Bottles
              </span>
            </div>
            {isMobile ? (
              <>
                {activeIndex === "cocktails" && (
                  <SearchResults
                    index="cocktails"
                    title="Cocktails"
                    setSearchString={setSearchString}
                    setTotal={setCocktailsTotal}
                    filters={filters}
                    {...props}
                  />
                )}
                {activeIndex === "products" && (
                  <SearchResults
                    index="products"
                    title="Bottles"
                    setTotal={setProductsTotal}
                    setSearchString={setSearchString}
                    {...props}
                  />
                )}
              </>
            ) : (
              <>
                <SearchResults
                  index="cocktails"
                  title="Cocktails"
                  setTotal={setCocktailsTotal}
                  setSearchString={setSearchString}
                  filters={filters}
                  {...props}
                />
                <SearchResults
                  index="products"
                  title="Bottles"
                  setTotal={setProductsTotal}
                  setSearchString={setSearchString}
                  {...props}
                />
              </>
            )}
            {noResults && <h1>no results</h1>}
          </div>
        </div>
      </InstantSearch>
    </>
  );
};

const LocaleSelector = () => {
  const router = useRouter();
  const { locale, pathname, asPath, query } = router;
  const [isOpen, setIsOpen] = useState(false);
  const [ref, hovering] = useHover();
  const { t } = useTranslation();

  const locales = { en: "English", es: "Español", nb: "Norsk" };
  const setLocale = (nextLocale) => {
    if (nextLocale != locale) {
      Cookies.set("NEXT_LOCALE", nextLocale);
      router.push({ pathname, query }, asPath, { locale: nextLocale });
      setIsOpen(false);
    }
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <div
        onClick={() => setIsOpen(true)}
        className={cx(s.localeItem, s.activeLocale, "activeLocale")}
      >
        <img src={`/img/flags/${locale}.svg`} />
        {locales[locale]}
      </div>

      {isOpen && (
        <div ref={ref} className={cx(s.localeList, "Shadow")}>
          <div className={cx(s.localeListHeader)}>
            <b>{t("Select language")}</b>
            <span onClick={() => setIsOpen(false)}>
              <img alt="" src="/img/icons/close-solid.svg" />
            </span>
          </div>
          <div className={cx(s.localeListScroll)}>
            {Object.keys(locales).map((loc) => {
              if (!router.locales.includes(loc)) {
                return <></>;
              }
              return (
                <div
                  className={cx(
                    s.localeItem,
                    s.localeListItem,
                    "localeListItem",
                    { active: loc == locale },
                  )}
                  key={loc}
                  onClick={() => setLocale(loc)}
                >
                  <img src={`/img/flags/${loc}.svg`} />
                  {locales[loc]}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </OutsideClickHandler>
  );
};

const HomeMenu = ({
  label,
  className,
  children,
  setShowLoginModal,
  setLoginModalState,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [closedSearch, setClosedSearch] = useState(false);
  const { t } = useTranslation();
  const { locale, pathname } = useRouter();

  const horeca = pathname.startsWith("/horeca");

  const handleClick = () => {
    setActive(!active);
    setOpen(!open);
  };
  const handleTrigger = () => {
    setOpen(!open);
  };
  const { data: session } = useSession();

  const {
    data: { filters },
  } = useQuery("filters/all");

  return (
    <header className={cx("homeMenu", s.homeMenu)}>
      <nav>
        <div className={cx("container", s.container)}>
          <Link href="/" className={s.logoHolder}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 647.4 647.4">
              <path
                className="st0"
                d="M323.7,0C144.9,0,0,144.9,0,323.7c0,178.8,144.9,323.7,323.7,323.7c178.8,0,323.7-144.9,323.7-323.7C647.4,144.9,502.5,0,323.7,0z M323.7,604.9C168.4,604.9,42.5,479,42.5,323.7c0-155.3,125.9-281.2,281.2-281.2s281.2,125.9,281.2,281.2C604.9,479,479,604.9,323.7,604.9z M482.2,167.4L194.8,509.8l-32.6-27.3L449.6,140L482.2,167.4z M243.4,207.5c-8.5-11.1-21.2-18.9-36.6-18.9c-24.5,0-42.8,19.3-42.8,43.4c0,24.8,18,43.1,43.8,43.1c17.3,0,31-10.5,37.9-20.9l31.3,26.3c-18.4,23.6-41.5,33.8-70.5,33.8c-49,0-84.9-36.6-84.9-82.3c0-46,36.9-82.6,85.2-82.6c25.1,0,47.8,10.7,62.7,27L243.4,207.5z M522.8,418.2c0,45.7-35.9,82.3-84.9,82.3c-29,0-52.1-10.3-70.5-33.8l27.1-32.3c1.3,2.2,3.4,4.9,4.8,6.9c7.9,10,17.1,16,26.3,18.6c3.5,0.9,7.1,1.5,10.9,1.5c25.8,0,43.8-18.3,43.8-43.1c0-24.2-18.3-43.4-42.8-43.4c-12.8,0-23.7,5.4-31.9,13.6l-30.8-25.8c14.9-16.3,37.6-27,62.7-27C485.9,335.6,522.8,372.2,522.8,418.2z"
              />
            </svg>
            <img
              alt="Cocktail Club | Logo symbol"
              src="/img/cc_symbol-black.png"
              className="hidden"
            />
          </Link>

          <OutsideClickHandler
            onOutsideClick={() => {
              setClosedSearch(true);
            }}
          >
            <SearchHolder
              setClosedSearch={setClosedSearch}
              closed={closedSearch}
              {...props}
            />
          </OutsideClickHandler>
          <div className={cx("extraMenu", s.extraMenu, active)}>
            <ul>
              <li>
                <NavLink href="/cocktails">
                  <a onClick={handleClick}>{t("Recipes")}</a>
                </NavLink>
              </li>
              <li>
                <NavLink href="/bar-set">
                  <a onClick={handleClick}>{t("Bar set")}</a>
                </NavLink>
              </li>
              <li>
                <NavLink href="/journal/getting-started">
                  <a onClick={handleClick}>{t("Get started")}</a>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={cx("menuHolder", s.menuHolder, open ? "" : s.closed)}>
            <div>
              <ul>
                <li>
                  <NavLink href="/cocktails">
                    <a onClick={handleClick}>
                      {t("Recipes")}
                      <Icon size="far" svg="chevron-down" />
                    </a>
                  </NavLink>
                  <ul
                    className={cx("dropdownMenuHolder", s.dropdownMenuHolder)}
                  >
                    <div className={cx("Container container")}>
                      <li>
                        <NavLink href="/spirits">
                          <a disabled>{t("Spirits")}</a>
                        </NavLink>
                        <ul>
                          {filters ? (
                            <>
                              {filters.spirits.map((item, id) => {
                                return (
                                  <li key={id}>
                                    <Link
                                      href={`/cocktails?spirits=${item.slug}`}
                                    >
                                      {translate(item, "name", locale)}
                                    </Link>
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </li>
                      <li>
                        <NavLink href="/occasions">
                          <a disabled>{t("Occasions")}</a>
                        </NavLink>
                        <ul>
                          {filters ? (
                            <>
                              {filters.occasions.map((item, id) => {
                                return (
                                  <li key={id}>
                                    <Link
                                      href={`/cocktails?occasions=${item.slug}`}
                                    >
                                      {t(item.name)}
                                    </Link>
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </li>

                      <li>
                        <NavLink href="/flavors">
                          <a disabled>{t("Flavors")}</a>
                        </NavLink>
                        <ul>
                          {filters ? (
                            <>
                              {filters.flavors.map((item, id) => {
                                return (
                                  <li key={id}>
                                    <Link
                                      href={`/cocktails?flavors=${item.slug}`}
                                    >
                                      {translate(item, "name", locale)}
                                    </Link>
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </li>
                      <li>
                        <NavLink href="/difficulty">
                          <a disabled>{t("Difficulty")}</a>
                        </NavLink>
                        <ul>
                          {filters ? (
                            <>
                              {filters.difficulties.map((item, id) => {
                                return (
                                  <li key={id}>
                                    <Link
                                      href={`/cocktails?difficulties=${item.slug}`}
                                    >
                                      {t(item.name)}
                                    </Link>
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </li>
                      <li>
                        <NavLink href="/glasses">
                          <a disabled>{t("Glass")}</a>
                        </NavLink>
                        <ul>
                          {filters ? (
                            <>
                              {filters.glasses.map((item, id) => {
                                return (
                                  <li key={id}>
                                    <Link
                                      href={`/cocktails?glasses=${item.slug}`}
                                    >
                                      {t(item.name)}
                                    </Link>
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </li>
                    </div>
                  </ul>
                </li>
                <li>
                  <NavLink href="/products">
                    <a onClick={handleClick}>{t("Bottles")}</a>
                  </NavLink>
                </li>
                <li>
                  <NavLink href="https://cocktailclubtools.com/">
                    <a target="_blank" onClick={handleClick}>
                      {t("Bar tools")}
                    </a>
                  </NavLink>
                </li>
                <li>
                  <NavLink href="/journal/getting-started">
                    <a onClick={handleClick}>{t("Get started")}</a>
                  </NavLink>
                </li>
                <li className={cx(s.mobileOnly)}>
                  <NavLink href="/journal/">
                    <a onClick={handleClick}>{t("Journal")}</a>
                  </NavLink>
                </li>
                {/*<li>
                  <NavLink href="/events">
                    <a onClick={handleClick}>{t("Events")}</a>
                  </NavLink>
                </li>*/}
                <li>
                  <NavLink href="/partner">
                    <a onClick={handleClick}>{t("Horeca")}</a>
                  </NavLink>
                </li>
              </ul>
              <ul className={cx("actionHolder", s.actionHolder)}>
                <li>
                  <NavLink href="/cart">
                    <a className={cx(s.cartBtn)} onClick={handleClick}>
                      {t("Shopping cart")}
                      <Icon svg="bag-shopping" size="fal" />
                    </a>
                  </NavLink>
                </li>
                {session?.user ? (
                  <li>
                    <NavLink href="/profile/menus">
                      <a className={cx(s.menusBtn)} onClick={handleClick}>
                        {t("My menus")}
                        <Icon svg="heart" size="fal" />
                      </a>
                    </NavLink>
                  </li>
                ) : (
                  <li>
                    <a
                      className={cx(s.menuLoginBtn)}
                      onClick={() => setShowLoginModal(true)}
                      title="Log in"
                    >
                      {t("Log in")}
                    </a>
                  </li>
                )}
                {session?.user ? (
                  <li>
                    <NavLink href="/profile">
                      <a
                        className={cx(s.myProfileBtn)}
                        onClick={handleClick}
                        title="My profile"
                      >
                        {t("My profile")}
                        <Icon svg="user" size="fal" />
                      </a>
                    </NavLink>
                  </li>
                ) : (
                  !horeca && (
                    <li>
                      <a
                        className={cx("Button Primary Full", s.menuRegisterBtn)}
                        onClick={() =>
                          setLoginModalState({ show: true, index: 1 })
                        }
                      >
                        {t("Get free access")}
                      </a>
                    </li>
                  )
                )}
                <li className={cx(s.menuLocaleSelectorLi)}>
                  <LocaleSelector />
                </li>
              </ul>
            </div>
          </div>
          <div
            className={cx("menuTrigger", s.menuTrigger, open ? "" : s.closed)}
            onClick={handleTrigger}
          >
            <span />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HomeMenu;
